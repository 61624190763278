html {
  --hsl-h: 188.9;
  --hsl-s: 55.1%;
  --hsl-l: 38.4%;
  --hs:var(--hsl-h), var(--hsl-s);
  --hsl:var(--hs), var(--hsl-l);

  --color:       hsl(var(--hsl));
  --color-light: hsl(var(--hs), calc(var(--hsl-l) + (100% - var(--hsl-l)) * .9 ) );
  --color-dark:  hsl(var(--hs), calc(var(--hsl-l) * .7 ) );

  /* color 2 */
  --hsl2-h:calc(var(--hsl-h) - 60);
  --hsl2-s:var(--hsl-s);
  --hsl2-l:var(--hsl-l);
  --hs2:var(--hsl2-h), var(--hsl2-s);
  --hsl2:var(--hs2), var(--hsl2-l);

  --color2:       hsl(var(--hsl2));
  --color2-light: hsl(var(--hs2), calc(var(--hsl2-l) + (100% - var(--hsl2-l)) * .9 ) );
  --color2-dark:  hsl(var(--hs2), calc(var(--hsl2-l) * .7 ) );

  /* named */
  --color-bg:   hsl(var(--hs), 99.5%);
  --color-text: hsl(var(--hs), calc(var(--hsl-l) * .4 ) );
  --color-area: var(--color-light);
  --color-line: hsl(var(--hs), 10%, .4);

  /* misc */
  --width:42rem; /* rename? --main-width, --content-width */
  --line-width: 1px;
  --gap:1rem;
  --radius: .3rem;

  --shadow-x:0;
  --shadow-y:.25rem;
  --shadow-blur:.7rem;
  --shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) #0003;
}
/*
@media (prefers-color-scheme: dark) {
  html {
    --color-light:   hsl(var(--hs), calc(var(--hsl-l) * .7 ) );
    --color-dark:    hsl(var(--hs), calc(var(--hsl-l) + (100% - var(--hsl-l)) * .9 ) );

    --color2-light: hsl(var(--hs2), calc(var(--hsl2-l) * .7 ) );
    --color2-dark:  hsl(var(--hs2), calc(var(--hsl2-l) + (100% - var(--hsl2-l)) * .9 ) );

    --color-bg:    hsl(var(--hs), calc(var(--hsl-l) * .4 ) );
    --color-text:  hsl(var(--hs), 99.5%);
  }
}
*/


/*

# Some ideas:

--gap\.5:calc(var(--gap)/2);
--gap2:calc(var(--gap)*2);

--gap-xs:.25rem;
--gap-s:.5rem;
--gap-m:2rem;

## golden ratio?
--gap-xs: calc(var(--gap-s) / 1.618);
--gap-s:  calc(var(--gap)   / 1.618);
--gap-m:  calc(var(--gap)   * 1.618);
--gap-l:  calc(var(--gap-m) * 1.618);
--gap-x:  calc(var(--gap-l) * 1.618);
*/
/*
--color-red :     hsl(0deg,   var(--hsl-s), var(--hsl-l));
--color-orange :  hsl(30deg,  var(--hsl-s), var(--hsl-l));
--color-yellow :  hsl(60deg,  var(--hsl-s), var(--hsl-l));
--color-green :   hsl(120deg, var(--hsl-s), var(--hsl-l));
--color-cyan :    hsl(180deg, var(--hsl-s), var(--hsl-l));
--color-blue :    hsl(240deg, var(--hsl-s), var(--hsl-l));
--color-violet :  hsl(270deg, var(--hsl-s), var(--hsl-l));
--color-purple :  hsl(285deg, var(--hsl-s), var(--hsl-l));
--color-magenta : hsl(300deg, var(--hsl-s), var(--hsl-l));

--color-gray:       hsl(var(--hsl-h), 4%, 60%);
--color-gray-light: hsl(var(--hsl-h), 4%, 90%);
--color-gray-dark:  hsl(var(--hsl-h), 4%, 40%);
*/
/* --font:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif; */


/* from pico.css *
--ico-chevron:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(65, 84, 98, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='6 9 12 15 18 9'></polyline></svg>");
/*
--ico-chevron:  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(65, 84, 98, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
--ico-date:     url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(65, 84, 98, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
--ico-time:     url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(65, 84, 98, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
--ico-search:   url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(65, 84, 98, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
--ico-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
--ico-minus:    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
--ico-valid:    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(56, 142, 60, 0.999)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
--ico-invalid:  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(198, 40, 40, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
*/
